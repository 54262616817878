// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var content = "Z_h";
export var authorDetails = "Z_c5";
export var authorName = "Z_c6";
export var fullBioLink = "Z_c7";
export var socialIcons = "Z_bY";